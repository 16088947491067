import { template as template_d74bbc72f276495c85726a84ac4ca926 } from "@ember/template-compiler";
const SidebarSectionMessage = template_d74bbc72f276495c85726a84ac4ca926(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
