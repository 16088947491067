import { template as template_90455ca6e19e43f4b77ab34754b74085 } from "@ember/template-compiler";
const WelcomeHeader = template_90455ca6e19e43f4b77ab34754b74085(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
