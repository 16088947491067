import { template as template_2d224239070d4e7d91c237f29e1e5861 } from "@ember/template-compiler";
const FKLabel = template_2d224239070d4e7d91c237f29e1e5861(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
